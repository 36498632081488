<template>
    <div class="AboutUs Wrapper-section visible" id="AboutUsPage">
        <div class="Trigger TriggerTop" id="AboutUsTriggerTop"></div>
        <div :style="`background-image: url('${videoThumb}')`" class="AboutUsVideo">
            <div :style="`background-image: url('${videoXSOverlay}')`" class="AboutUsVideo-XSOverlay"></div>
            <video :poster="videoThumb" :src="videoSources[0].src" autoplay class="AboutUsVideo-video" loop muted playsinline="playsinline">
                <source :key="index" :size="video.size" :src="video.src" :type="video.type" v-for="(video, index) in videoSources" />
            </video>
        </div>
        <div class="AboutUsIntro" id="AboutUsIntro">
            <div class="AboutUsIntro-container" id="AboutUsIntro-container">
                <h1 class="title AboutUs-title">TroubleMakers.<br />RuleBreakers.<br />GameChangers.</h1>
            </div>
        </div>
        <div class="Trigger TriggerBottom" id="AboutUsTriggerBottom"></div>
    </div>
</template>

<script>
    export default {
        name: 'AboutUs',
        data() {
            return {
                sceneTimer: null,
                publicPath: process.env.BASE_URL
            };
        },
        computed: {
            videoSources() {
                return [
                    {
                        src: `${this.publicPath}videos/JFIReel_1080.mp4`,
                        type: 'video/m4v',
                        size: '1080'
                    },
                    {
                        src: `${this.publicPath}videos/JFIReel_1080.webm`,
                        type: 'video/webm',
                        size: '1080'
                    }
                ];
            },
            videoThumb() {
                return `${this.publicPath}videos/JFIReel_1080.jpg`;
            },
            videoXSOverlay() {
                return `${this.publicPath}videos/JFIReel_1080_overlay.jpg`;
            }
        }
    };
</script>
